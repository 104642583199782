import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TablePagination from "@material-ui/core/TablePagination";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useHistory } from "react-router-dom";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Popover from "@material-ui/core/Popover";
import Lottie from "lottie-react";
import uploadAnimation from "../assets/lottie/upload.json";
import confettiAnimation from "../assets/lottie/confetti.json";
import warningAnimation from "../assets/lottie/warning.json";

const useStyles = makeStyles({
  table: {
    marginTop: 30,
    width: 700,
  },
  row: {
    height: 80,
  },
  success: {
    color: "green",
  },
  warning: {
    color: "orange",
  },
  failure: {
    color: "red",
  },
  enabledIcon: {
    color: "#808080",
  },
  textColor: {
    color: "#414141",
    marginBottom: 4,
  },
  header: {
    color: "#414141",
    marginBottom: 4,
    marginRight: 15,
  },
  disabledIcon: {
    color: "#d3d3d3",
  },
  spinner: {
    marginTop: 250,
  },
  goBack: {
    marginTop: 25,
    marginLeft: 25,
  },
  summary: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subSummary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    marginRight: 10,
    marginLeft: 10,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: 10,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InvoicesSendingAction = () => {
  const classes = useStyles();
  const history = useHistory();

  const { actionId } = useParams();

  const [invoices, setInvoices] = useState([]);
  const [actionFinished, setActionFinished] = useState(false);
  const [numberOfFiles, setNumberOfFiles] = useState(null);
  const [page, setPage] = useState(0);
  const [pdfNotFoundError, setPdfNotFoundError] = useState(false);

  const delay = async (delayInMs) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delayInMs);
    });
  };

  const checkIfActionFinished = (actionInvoices, actionFilesNumber) => {
    if (actionInvoices?.length === actionFilesNumber) {
      return actionInvoices.every(
        (invoiceInAction) =>
          invoiceInAction.invoiceSendingOverviewStatus === "SUCCESS" ||
          invoiceInAction.invoiceSendingOverviewStatus === "FAILURE"
      );
    }
    return false;
  };

  const getOneAction = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/actions/${actionId}`,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return data;
  };

  useEffect(() => {
    async function polling() {
      for (let index = 0; index < 30; index++) {
        const action = await getOneAction();
        setNumberOfFiles(action.numberOfFiles);
        setInvoices(action.invoices);
        const isActionFinished = checkIfActionFinished(
          action.invoices,
          action.numberOfFiles
        );
        if (isActionFinished) {
          break;
        }
        await delay(10000);
      }
      setActionFinished(true);
    }

    polling();
  }, []);

  useEffect(() => {}, [invoices]);

  useEffect(() => {}, [actionFinished]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const displayPdf = async (filename) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/files/${filename}`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      setPdfNotFoundError(true);
    }
  };

  const handleCloseOfPdfNotFoundError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPdfNotFoundError(false);
  };

  const emptyRows = 5 - Math.min(5, numberOfFiles - page * 5);

  const [warningAnchorEl, setWarningAnchorEl] = useState(null);
  const handleWarningPopoverOpen = (event) => {
    setWarningAnchorEl(event.currentTarget);
  };
  const handleWarningPopoverClose = () => {
    setWarningAnchorEl(null);
  };
  const warningOpen = Boolean(warningAnchorEl);

  const [firstErrorAnchorEl, setFirstErrorAnchorEl] = useState(null);
  const handleFirstErrorPopoverOpen = (event) => {
    setFirstErrorAnchorEl(event.currentTarget);
  };
  const handleFirstErrorPopoverClose = () => {
    setFirstErrorAnchorEl(null);
  };
  const firstErrorOpen = Boolean(firstErrorAnchorEl);

  const [secondErrorAnchorEl, setSecondErrorAnchorEl] = useState(null);
  const handleSecondErrorPopoverOpen = (event) => {
    setSecondErrorAnchorEl(event.currentTarget);
  };
  const handleSecondErrorPopoverClose = () => {
    setSecondErrorAnchorEl(null);
  };
  const secondErrorOpen = Boolean(secondErrorAnchorEl);

  const [thirdErrorAnchorEl, setThirdErrorAnchorEl] = useState(null);
  const handleThirdErrorPopoverOpen = (event) => {
    setThirdErrorAnchorEl(event.currentTarget);
  };
  const handleThirdErrorPopoverClose = () => {
    setThirdErrorAnchorEl(null);
  };
  const thirdErrorOpen = Boolean(thirdErrorAnchorEl);

  const [generalSuccessAnchorEl, setGeneralSuccessAnchorEl] = useState(null);
  const handleGeneralSuccessPopoverOpen = (event) => {
    setGeneralSuccessAnchorEl(event.currentTarget);
  };
  const handleGeneralSuccessPopoverClose = () => {
    setGeneralSuccessAnchorEl(null);
  };
  const generalSuccessOpen = Boolean(generalSuccessAnchorEl);

  const [generalWarningAnchorEl, setGeneralWarningAnchorEl] = useState(null);
  const handleGeneralWarningPopoverOpen = (event) => {
    setGeneralWarningAnchorEl(event.currentTarget);
  };
  const handleGeneralWarningPopoverClose = () => {
    setGeneralWarningAnchorEl(null);
  };
  const generalWarningOpen = Boolean(generalWarningAnchorEl);

  const [generalUploadAnchorEl, setGeneralUploadAnchorEl] = useState(null);
  const handleGeneralUploadPopoverOpen = (event) => {
    setGeneralUploadAnchorEl(event.currentTarget);
  };
  const handleGeneralUploadPopoverClose = () => {
    setGeneralUploadAnchorEl(null);
  };
  const generalUploadOpen = Boolean(generalUploadAnchorEl);

  return (
    <>
      <IconButton
        className={classes.goBack}
        onClick={() => {
          history.goBack();
        }}
      >
        <KeyboardBackspaceIcon fontSize={"large"} color={"primary"} />
      </IconButton>
      <Container>
        <Header>
          <Typography variant="h3" className={classes.header}>
            Upload
          </Typography>
          {invoices.some(
            (i) => i.invoiceSendingOverviewStatus === "IN_PROGRESS"
          ) && (
            <>
              <Lottie
                animationData={uploadAnimation}
                style={{ width: 70, paddingBottom: 5 }}
                aria-owns={generalUploadOpen ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handleGeneralUploadPopoverOpen}
                onMouseLeave={handleGeneralUploadPopoverClose}
              />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={generalUploadOpen}
                anchorEl={generalUploadAnchorEl}
                anchorOrigin={{
                  vertical: 10,
                  horizontal: 90,
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handleGeneralUploadPopoverClose}
                disableRestoreFocus
              >
                <Typography>Avise werden hochgeladen. Bitte warten.</Typography>
              </Popover>
            </>
          )}
          {invoices.every(
            (i) => i.invoiceSendingOverviewStatus === "SUCCESS"
          ) && (
            <>
              <Lottie
                animationData={confettiAnimation}
                style={{ width: 70, paddingBottom: 5 }}
                loop={2}
                aria-owns={
                  generalSuccessOpen ? "mouse-over-popover" : undefined
                }
                aria-haspopup="true"
                onMouseEnter={handleGeneralSuccessPopoverOpen}
                onMouseLeave={handleGeneralSuccessPopoverClose}
              />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={generalSuccessOpen}
                anchorEl={generalSuccessAnchorEl}
                anchorOrigin={{
                  vertical: 10,
                  horizontal: 90,
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handleGeneralSuccessPopoverClose}
                disableRestoreFocus
              >
                <Typography>
                  Alle Avise wurden erfolgreich an Abas gesendet.
                </Typography>
              </Popover>
            </>
          )}
          {invoices.every(
            (i) => i.invoiceSendingOverviewStatus !== "IN_PROGRESS"
          ) &&
            invoices.some(
              (i) => i.invoiceSendingOverviewStatus !== "SUCCESS"
            ) && (
              <>
                <Lottie
                  animationData={warningAnimation}
                  style={{ width: 70, paddingBottom: 5 }}
                  loop={2}
                  aria-owns={
                    generalWarningOpen ? "mouse-over-popover" : undefined
                  }
                  aria-haspopup="true"
                  onMouseEnter={handleGeneralWarningPopoverOpen}
                  onMouseLeave={handleGeneralWarningPopoverClose}
                />
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={generalWarningOpen}
                  anchorEl={generalWarningAnchorEl}
                  anchorOrigin={{
                    vertical: 0,
                    horizontal: 90,
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handleGeneralWarningPopoverClose}
                  disableRestoreFocus
                >
                  <Typography>
                    Es gab Probleme bei mindestens einer Avis. Bitte prüfen Sie
                    <br />
                    den Fehler in der untenstehenden Liste.
                  </Typography>
                </Popover>
              </>
            )}
        </Header>
        {invoices?.length > 0 ? (
          <>
            <div className={classes.summary}>
              <div className={classes.subSummary}>
                <CheckOutlinedIcon className={classes.success} />
                <Typography variant="h5" className={classes.textColor}>
                  {
                    invoices.filter(
                      (i) =>
                        i.invoiceSendingOverviewStatus === "SUCCESS" &&
                        i.validated
                    ).length
                  }
                </Typography>
              </div>
              <div className={classes.subSummary}>
                <ErrorOutlineIcon className={classes.warning} />
                <Typography variant="h5" className={classes.textColor}>
                  {
                    invoices.filter(
                      (i) =>
                        i.invoiceSendingOverviewStatus === "SUCCESS" &&
                        !i.validated
                    ).length
                  }
                </Typography>
              </div>
              <div className={classes.subSummary}>
                <CloseIcon className={classes.failure} />
                <Typography variant="h5" className={classes.textColor}>
                  {
                    invoices.filter(
                      (i) => i.invoiceSendingOverviewStatus === "FAILURE"
                    ).length
                  }
                </Typography>
              </div>
            </div>
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Rechnungsindex</TableCell>
                    <TableCell align="center">Dateinamen</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="right"></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {numberOfFiles &&
                    [...Array(numberOfFiles).keys()]
                      ?.slice(page * 5, page * 5 + 5)
                      .map((index) => (
                        <TableRow className={classes.row} key={"row.name"}>
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {invoices[index]?.invoiceId ? (
                              invoices[index]?.invoiceId
                            ) : (
                              <RemoveIcon />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {invoices[index]?.desktopFilename ? (
                              invoices[index]?.desktopFilename
                            ) : (
                              <RemoveIcon />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {invoices[index]?.invoiceSendingOverviewStatus ===
                              "SUCCESS" &&
                              invoices[index]?.validated === true && (
                                <CheckOutlinedIcon
                                  className={classes.success}
                                />
                              )}

                            {invoices[index]?.invoiceSendingOverviewStatus ===
                              "SUCCESS" &&
                              invoices[index]?.validated === false && (
                                <>
                                  <ErrorOutlineIcon
                                    className={classes.warning}
                                    aria-owns={
                                      warningOpen
                                        ? "mouse-over-popover"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handleWarningPopoverOpen}
                                    onMouseLeave={handleWarningPopoverClose}
                                  />
                                  <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                      paper: classes.paper,
                                    }}
                                    open={warningOpen}
                                    anchorEl={warningAnchorEl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    onClose={handleWarningPopoverClose}
                                    disableRestoreFocus
                                  >
                                    <Typography>
                                      Bei der Extraktion der Avisdaten wurden
                                      Fehler festgestellt.
                                      <br />
                                      Bitte prüfen Sie die Daten in Abas.
                                    </Typography>
                                  </Popover>
                                </>
                              )}

                            {invoices[index]?.invoiceSendingOverviewStatus ===
                              "IN_PROGRESS" && <CircularProgress />}

                            {!invoices[index]?.invoiceSendingOverviewStatus && (
                              <CircularProgress />
                            )}

                            {invoices[index]?.invoiceSendingOverviewStatus ===
                              "FAILURE" &&
                              invoices[index]?.invoiceSendingDetailedStatus !==
                                "DATA_EXTRACTED_FROM_INVOICE_FILE" &&
                              invoices[index]?.invoiceSendingDetailedStatus !==
                                "INVOICE_FILE_SAVED_TO_S3" && (
                                <>
                                  <CloseIcon
                                    className={classes.failure}
                                    aria-owns={
                                      firstErrorOpen
                                        ? "mouse-over-popover"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handleFirstErrorPopoverOpen}
                                    onMouseLeave={handleFirstErrorPopoverClose}
                                  />
                                  <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                      paper: classes.paper,
                                    }}
                                    open={firstErrorOpen}
                                    anchorEl={firstErrorAnchorEl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    onClose={handleFirstErrorPopoverClose}
                                    disableRestoreFocus
                                  >
                                    <Typography>
                                      Ein Fehler ist aufgetreten. Bitte
                                      versuchen Sie die Datei erneut
                                      <br />
                                      hochzuladen oder übertragen Sie die Daten
                                      manuell in Abas.
                                    </Typography>
                                  </Popover>
                                </>
                              )}

                            {invoices[index]?.invoiceSendingOverviewStatus ===
                              "FAILURE" &&
                              invoices[index]?.invoiceSendingDetailedStatus ===
                                "DATA_EXTRACTED_FROM_INVOICE_FILE" && (
                                <>
                                  <CloseIcon
                                    className={classes.failure}
                                    aria-owns={
                                      secondErrorOpen
                                        ? "mouse-over-popover"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handleSecondErrorPopoverOpen}
                                    onMouseLeave={handleSecondErrorPopoverClose}
                                  />
                                  <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                      paper: classes.paper,
                                    }}
                                    open={secondErrorOpen}
                                    anchorEl={secondErrorAnchorEl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    onClose={handleSecondErrorPopoverClose}
                                    disableRestoreFocus
                                  >
                                    <Typography>
                                      Die hochgeladene Datei gehört nicht zu den
                                      5 Avistypen,
                                      <br /> die das System aktuell bearbeiten
                                      kann.
                                    </Typography>
                                  </Popover>
                                </>
                              )}

                            {invoices[index]?.invoiceSendingOverviewStatus ===
                              "FAILURE" &&
                              invoices[index]?.invoiceSendingDetailedStatus ===
                                "INVOICE_FILE_SAVED_TO_S3" && (
                                <>
                                  <CloseIcon
                                    className={classes.failure}
                                    aria-owns={
                                      thirdErrorOpen
                                        ? "mouse-over-popover"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handleThirdErrorPopoverOpen}
                                    onMouseLeave={handleThirdErrorPopoverClose}
                                  />
                                  <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                      paper: classes.paper,
                                    }}
                                    open={thirdErrorOpen}
                                    anchorEl={thirdErrorAnchorEl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    onClose={handleThirdErrorPopoverClose}
                                    disableRestoreFocus
                                  >
                                    <Typography>
                                      Diese Datei wurde bereits an ABAS
                                      gesendet.
                                    </Typography>
                                  </Popover>
                                </>
                              )}
                          </TableCell>
                          {/* <TableCell align="right">
                            <IconButton
                              aria-label="delete"
                              disabled={!invoices[index]?.invoiceFileLocation}
                              onClick={() => {
                                displayPdf(
                                  invoices[index]?.invoiceFileLocation?.split(
                                    "/"
                                  )[1]
                                );
                              }}
                            >
                              <VisibilityIcon
                                className={
                                  invoices[index]?.invoiceFileLocation
                                    ? classes.enabledIcon
                                    : classes.disabledIcon
                                }
                              />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 80 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={numberOfFiles}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
            />

            <Snackbar
              open={pdfNotFoundError}
              autoHideDuration={6000}
              onClose={handleCloseOfPdfNotFoundError}
            >
              <Alert onClose={handleCloseOfPdfNotFoundError} severity="error">
                PDF mit Rechnung nicht gefunden!
              </Alert>
            </Snackbar>
          </>
        ) : (
          <CircularProgress className={classes.spinner} size={70} />
        )}
      </Container>
    </>
  );
};

export default InvoicesSendingAction;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
