import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Home = () => {
  return (
    <Container>
      <StyledLink to="/avis-an-abas-senden">
        <StyledButton variant="contained" color="primary">
          NEUE AVISE AN ABAS SENDEN
        </StyledButton>
      </StyledLink>
      <StyledLink to="/upload-uebersicht">
        <StyledButton variant="contained" color="primary">
          UPLOAD HISTORIE
        </StyledButton>
      </StyledLink>
    </Container>
  );
};

export default Home;

const Container = styled.div`
  margin-top: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  height: 200px;
  width: 300px;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 150px;
  margin: 0 50px 0 50px;
`;
