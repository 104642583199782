import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import FileUpload from "../components/FileUpload";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    maxWidth: 1000,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    marginTop: 50,
  },
  goBack: {
    marginTop: 25,
    marginLeft: 25,
  },
  spinner: {
    marginTop: 200,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SendInvoicesToAbas = () => {
  const classes = useStyles();
  const history = useHistory();

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [actionId, setActionId] = useState(null);

  const [filesLimitError, setFilesLimitError] = useState(false);
  const [pdfFormatError, setPdfFormatError] = useState(false);
  const [click, setClick] = useState(0);

  const clickOnce = () => {
    setClick(click + 1);
  };

  useEffect(() => {
    if (click === 1) {
      sendInvoicesToAbas();
    }
  }, [click]);

  const sendInvoicesToAbas = async () => {
    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("invoicesFiles", file);
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices/send-to-abas`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    if (data?.actionId) {
      setActionId(data.actionId);
    } else {
      console.log("sendInvoicesToAbas: no actionId in the response");
    }
  };

  useEffect(() => {
    if (actionId) {
      history.push({
        pathname: `/avis-upload/${actionId}`,
        state: { numberOfFiles: filesToUpload.length },
      });
    }
  }, [actionId]);

  const handleFileDelete = (index) => {
    setFilesToUpload(filesToUpload.filter((file, i) => i !== index));
  };

  const checkIfPdfFiles = (files) =>
    files?.every((file) => file?.type === "application/pdf");

  const checkFileLimit = (files) => {
    return filesToUpload?.length + files?.length <= 30;
  };

  const handleCloseOfFilesLimitError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFilesLimitError(false);
  };

  const handleCloseOfPdfFormatError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPdfFormatError(false);
  };

  return (
    <>
      <IconButton
        className={classes.goBack}
        onClick={() => {
          history.push({
            pathname: `/`,
          });
        }}
      >
        <HomeIcon fontSize={"large"} color={"primary"} />
      </IconButton>
      <Container>
        {click === 1 ? (
          <CircularProgress className={classes.spinner} size={70} />
        ) : (
          <>
            <StyledFileUpload
              onDrop={(files) => {
                if (checkIfPdfFiles(files)) {
                  if (checkFileLimit(files)) {
                    setFilesToUpload([...filesToUpload, ...files]);
                  } else {
                    setFilesLimitError(true);
                  }
                } else {
                  setPdfFormatError(true);
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={clickOnce}
              disabled={!filesToUpload?.length}
            >
              AVISE HOCHLADEN
            </Button>
            <div className={classes.chipContainer}>
              {filesToUpload.map((fileToUpload, index) => (
                <Chip
                  key={index}
                  label={fileToUpload.name}
                  onDelete={() => {
                    handleFileDelete(index);
                  }}
                />
              ))}
            </div>
            <Snackbar
              open={filesLimitError}
              autoHideDuration={6000}
              onClose={handleCloseOfFilesLimitError}
            >
              <Alert onClose={handleCloseOfFilesLimitError} severity="error">
                Es können maximal 30 Dateien in einem Upload hochgeladen werden!
              </Alert>
            </Snackbar>
            <Snackbar
              open={pdfFormatError}
              autoHideDuration={6000}
              onClose={handleCloseOfPdfFormatError}
            >
              <Alert onClose={handleCloseOfPdfFormatError} severity="error">
                Bitte wählen Sie nur PDF Dateien aus!
              </Alert>
            </Snackbar>
          </>
        )}
      </Container>
    </>
  );
};

export default SendInvoicesToAbas;

const Container = styled.div`
  margin-top: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFileUpload = styled(FileUpload)`
  height: 150px;
  width: 500px;
`;
