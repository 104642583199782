import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import ActionsList from "./pages/ActionsList";
import InvoicesSendingAction from "./pages/InvoicesSendingAction";
import SendInvoicesToAbas from "./pages/SendInvoicesToAbas";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/avis-an-abas-senden"
          exact
          component={SendInvoicesToAbas}
        />
        <Route path="/upload-uebersicht" exact component={ActionsList} />
        <Route
          path="/avis-upload/:actionId"
          exact
          component={InvoicesSendingAction}
        />
        <Route path="/" exact component={Home} />
      </Switch>
    </Router>
  );
};

export default App;
