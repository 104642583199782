import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import TablePagination from "@material-ui/core/TablePagination";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles({
  table: {
    marginTop: 103,
    width: 700,
  },
  row: {
    height: 80,
  },
  icon: {
    color: "#808080",
  },
  textColor: {
    color: "#414141",
  },
  spinner: {
    marginTop: 300,
  },
  goBack: {
    marginTop: 25,
    marginLeft: 25,
  },
});

const ActionsList = () => {
  const classes = useStyles();
  const history = useHistory();

  const [actions, setActions] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const getAllActions = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/actions`,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setActions(
        data?.reverse().map((action, index) => ({
          ordinalNumber: index + 1,
          actionId: action.actionId,
          createdAt: action.createdAt,
        }))
      );
    };

    getAllActions();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = 5 - Math.min(5, actions?.length - page * 5);

  return (
    <>
      <IconButton
        className={classes.goBack}
        onClick={() => {
          history.push({
            pathname: `/`,
          });
        }}
      >
        <HomeIcon fontSize={"large"} color={"primary"} />
      </IconButton>
      <Container>
        <Typography variant="h3" className={classes.textColor}>
          Upload Historie
        </Typography>
        {actions ? (
          <>
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Upload-ID</TableCell>
                    <TableCell align="center">Datum</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actions?.slice(page * 5, page * 5 + 5).map((action) => (
                    <TableRow className={classes.row} key={"row.name"}>
                      <TableCell align="center" component="th" scope="row">
                        {action.ordinalNumber}
                      </TableCell>
                      <TableCell align="center">{action.actionId}</TableCell>
                      <TableCell align="center">
                        {new Intl.DateTimeFormat("de-DE", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: false,
                        }).format(new Date(action.createdAt))}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            history.push({
                              pathname: `/avis-upload/${action.actionId}`,
                            });
                          }}
                        >
                          <ArrowForwardIosIcon className={classes.icon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 80 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={actions?.length}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
            />
          </>
        ) : (
          <CircularProgress className={classes.spinner} size={70} />
        )}
      </Container>
    </>
  );
};

export default ActionsList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
